// BookingsPage.duck.js
import {
  addGoogleCalendar,
  fetchPractitionerBookingConfig,
  hcpCreateEvent,
  saveBookingSlug,
  startStripeDashboardSession,
  updateBookingCalendars,
  updateBookingConfigurations,
  updateNotificationConfiguration
} from '../../util/api'

// ================ ACTION TYPES ================ //

export const FETCH_BOOKING_CONFIG_REQUEST = 'app/BookingsPage/FETCH_BOOKING_CONFIG_REQUEST'
export const FETCH_BOOKING_CONFIG_SUCCESS = 'app/BookingsPage/FETCH_BOOKING_CONFIG_SUCCESS'
export const FETCH_BOOKING_CONFIG_ERROR = 'app/BookingsPage/FETCH_BOOKING_CONFIG_ERROR'

export const CREATE_EVENT_REQUEST = 'app/BookingsPage/CREATE_EVENT_REQUEST'
export const CREATE_EVENT_SUCCESS = 'app/BookingsPage/CREATE_EVENT_SUCCESS'
export const CREATE_EVENT_ERROR = 'app/BookingsPage/CREATE_EVENT_ERROR'

export const UPDATE_EVENT_REQUEST = 'app/BookingsPage/UPDATE_EVENT_REQUEST'
export const UPDATE_EVENT_SUCCESS = 'app/BookingsPage/UPDATE_EVENT_SUCCESS'
export const UPDATE_EVENT_ERROR = 'app/BookingsPage/UPDATE_EVENT_ERROR'

export const UPDATE_CONFIGURATION_REQUEST = 'app/BookingsPage/UPDATE_CONFIGURATION_REQUEST'
export const UPDATE_CONFIGURATION_SUCCESS = 'app/BookingsPage/UPDATE_CONFIGURATION_SUCCESS'
export const UPDATE_CONFIGURATION_ERROR = 'app/BookingsPage/UPDATE_CONFIGURATION_ERROR'

export const UPDATE_BOOKING_CALENDARS_REQUEST = 'app/BookingsPage/UPDATE_BOOKING_CALENDARS_REQUEST'
export const UPDATE_BOOKING_CALENDARS_SUCCESS = 'app/BookingsPage/UPDATE_BOOKING_CALENDARS_SUCCESS'
export const UPDATE_BOOKING_CALENDARS_ERROR = 'app/BookingsPage/UPDATE_BOOKING_CALENDARS_ERROR'

export const SAVE_BOOKING_SLUG_REQUEST = 'app/BookingsPage/SAVE_BOOKING_SLUG_REQUEST'
export const SAVE_BOOKING_SLUG_SUCCESS = 'app/BookingsPage/SAVE_BOOKING_SLUG_SUCCESS'
export const SAVE_BOOKING_SLUG_ERROR = 'app/BookingsPage/SAVE_BOOKING_SLUG_ERROR'

export const UPDATE_NOTIFICATION_SETTINGS_REQUEST =
  'app/BookingsPage/UPDATE_NOTIFICATION_SETTINGS_REQUEST'
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS =
  'app/BookingsPage/UPDATE_NOTIFICATION_SETTINGS_SUCCESS'
export const UPDATE_NOTIFICATION_SETTINGS_ERROR =
  'app/BookingsPage/UPDATE_NOTIFICATION_SETTINGS_ERROR'

export const ADD_GOOGLE_CALENDAR_REQUEST = 'app/BookingsPage/ADD_GOOGLE_CALENDAR_REQUEST'
export const ADD_GOOGLE_CALENDAR_SUCCESS = 'app/BookingsPage/ADD_GOOGLE_CALENDAR_SUCCESS'
export const ADD_GOOGLE_CALENDAR_ERROR = 'app/BookingsPage/ADD_GOOGLE_CALENDAR_ERROR'
export const REMOVE_GOOGLE_CALENDAR_AUTH_URL = 'app/BookingsPage/REMOVE_GOOGLE_CALENDAR_AUTH_URL'

export const START_STRIPE_DASHBOARD_SESSION_REQUEST =
  'app/BookingsPage/START_STRIPE_DASHBOARD_SESSION_REQUEST'
export const START_STRIPE_DASHBOARD_SESSION_SUCCESS =
  'app/BookingsPage/START_STRIPE_DASHBOARD_SESSION_SUCCESS'
export const START_STRIPE_DASHBOARD_SESSION_ERROR =
  'app/BookingsPage/START_STRIPE_DASHBOARD_SESSION_ERROR'

// ================ REDUCER ================ //

const initialState = {
  openHours: [],
  configurations: {
    bookingConfig: {},
    configs: [],
    calendars: []
  },
  allowAdditionalGrants: false,
  fetchBookingConfigInProgress: false,
  fetchBookingConfigError: null,
  createEventInProgress: false,
  createEventError: null,
  updateEventInProgress: false,
  updateEventError: null,
  updateConfigurationInProgress: false,
  updateConfigurationError: null,
  updateBookingCalendarsInProgress: false,
  updateBookingCalendarsError: null,
  saveBookingSlugInProgress: false,
  saveBookingSlugError: null,

  updateNotificationSettingsInProgress: false,
  updateNotificationSettingsError: null,

  addGoogleCalendarAuthUrl: null,
  addGoogleCalendarInProgress: false,
  addGoogleCalendarError: null,

  startStripeDashboardSessionUrl: null,
  startStripeDashboardSessionInProgress: false,
  startStripeDashboardSessionError: null
}

const appointmentsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_BOOKING_CONFIG_REQUEST:
      return { ...state, fetchBookingConfigInProgress: true, fetchBookingConfigError: null }

    case FETCH_BOOKING_CONFIG_SUCCESS:
      return {
        ...state,
        fetchBookingConfigInProgress: false,
        configurations: payload.configurations,
        openHours: payload.openHours,
        allowAdditionalGrants: payload.allowAdditionalGrants
      }

    case FETCH_BOOKING_CONFIG_ERROR:
      return { ...state, fetchBookingConfigInProgress: false, fetchBookingConfigError: payload }

    case UPDATE_CONFIGURATION_REQUEST:
      return {
        ...state,
        updateConfigurationInProgress: true,
        updateConfigurationError: null
      }

    case UPDATE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        updateConfigurationInProgress: false,
        configurations: {
          ...state.configurations,
          configs: state.configurations.configs.map((config) =>
            config.id === payload.id ? payload : config
          )
        }
      }

    case UPDATE_CONFIGURATION_ERROR:
      return {
        ...state,
        updateConfigurationInProgress: false,
        updateConfigurationError: payload
      }

    case UPDATE_BOOKING_CALENDARS_REQUEST:
      return {
        ...state,
        updateBookingCalendarsInProgress: true,
        updateBookingCalendarsError: null
      }

    case UPDATE_BOOKING_CALENDARS_SUCCESS:
      return {
        ...state,
        updateBookingCalendarsInProgress: false,
        configurations: {
          ...state.configurations,
          calendars: payload.calendars,
          configs: payload.configs
        }
      }

    case UPDATE_BOOKING_CALENDARS_ERROR:
      return {
        ...state,
        updateBookingCalendarsInProgress: false,
        updateBookingCalendarsError: payload
      }

    case SAVE_BOOKING_SLUG_REQUEST:
      return {
        ...state,
        saveBookingSlugInProgress: true,
        saveBookingSlugError: null
      }

    case SAVE_BOOKING_SLUG_SUCCESS:
      return {
        ...state,
        saveBookingSlugInProgress: false,
        configurations: {
          ...state.configurations,
          bookingConfig: payload.bookingConfig
        }
      }

    case SAVE_BOOKING_SLUG_ERROR:
      return {
        ...state,
        saveBookingSlugInProgress: false,
        saveBookingSlugError: payload
      }

    case UPDATE_NOTIFICATION_SETTINGS_REQUEST:
      return {
        ...state,
        updateNotificationSettingsInProgress: true,
        updateNotificationSettingsError: null
      }
    case UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      console.log({
        ...state,
        updateNotificationSettingsInProgress: false,
        configurations: {
          ...state.configurations,
          configs: payload.configs
        }
      })
      return {
        ...state,
        updateNotificationSettingsInProgress: false,
        configurations: {
          ...state.configurations,
          configs: payload.configs
        }
      }
    case UPDATE_NOTIFICATION_SETTINGS_ERROR:
      return {
        ...state,
        updateNotificationSettingsInProgress: false,
        updateNotificationSettingsError: payload
      }

    case ADD_GOOGLE_CALENDAR_REQUEST:
      return {
        ...state,
        addGoogleCalendarAuthUrl: null,
        addGoogleCalendarInProgress: true,
        addGoogleCalendarError: null
      }
    case ADD_GOOGLE_CALENDAR_SUCCESS:
      return {
        ...state,
        addGoogleCalendarAuthUrl: payload.redirectUrl,
        addGoogleCalendarInProgress: false
      }
    case ADD_GOOGLE_CALENDAR_ERROR:
      return {
        ...state,
        addGoogleCalendarAuthUrl: null,
        addGoogleCalendarInProgress: false,
        addGoogleCalendarError: payload
      }
    case REMOVE_GOOGLE_CALENDAR_AUTH_URL:
      return {
        ...state,
        addGoogleCalendarAuthUrl: null
      }

    case START_STRIPE_DASHBOARD_SESSION_REQUEST:
      return {
        ...state,
        startStripeDashboardSessionInProgress: true,
        startStripeDashboardSessionError: null
      }
    case START_STRIPE_DASHBOARD_SESSION_SUCCESS:
      return {
        ...state,
        startStripeDashboardSessionInProgress: false,
        startStripeDashboardSessionUrl: payload.url
      }
    case START_STRIPE_DASHBOARD_SESSION_ERROR:
      return {
        ...state,
        startStripeDashboardSessionInProgress: false,
        startStripeDashboardSessionError: payload
      }

    default:
      return state
  }
}

export default appointmentsReducer

// ================ ACTION CREATORS ================ //

export const fetchBookingConfigRequest = () => ({
  type: FETCH_BOOKING_CONFIG_REQUEST
})

export const fetchBookingConfigSuccess = (payload) => ({
  type: FETCH_BOOKING_CONFIG_SUCCESS,
  payload
})

export const fetchBookingConfigError = (error) => ({
  type: FETCH_BOOKING_CONFIG_ERROR,
  payload: error
})

export const createEventRequest = () => ({
  type: CREATE_EVENT_REQUEST
})

export const createEventSuccess = (event) => ({
  type: CREATE_EVENT_SUCCESS,
  payload: event
})

export const createEventError = (error) => ({
  type: CREATE_EVENT_ERROR,
  payload: error
})

export const updateEventRequest = () => ({
  type: UPDATE_EVENT_REQUEST
})

export const updateEventSuccess = (event) => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: event
})

export const updateEventError = (error) => ({
  type: UPDATE_EVENT_ERROR,
  payload: error
})

export const updateConfigurationRequest = () => ({
  type: UPDATE_CONFIGURATION_REQUEST
})
export const updateConfigurationSuccess = (configuration) => ({
  type: UPDATE_CONFIGURATION_SUCCESS,
  payload: configuration
})
export const updateConfigurationError = (error) => ({
  type: UPDATE_CONFIGURATION_ERROR,
  payload: error
})
export const updateBookingCalendarsRequest = () => ({
  type: UPDATE_BOOKING_CALENDARS_REQUEST
})
export const updateBookingCalendarsSuccess = (configurations) => ({
  type: UPDATE_BOOKING_CALENDARS_SUCCESS,
  payload: configurations
})
export const updateBookingCalendarsError = (error) => ({
  type: UPDATE_BOOKING_CALENDARS_ERROR,
  payload: error
})

export const saveBookingSlugRequest = () => ({
  type: SAVE_BOOKING_SLUG_REQUEST
})
export const saveBookingSlugSuccess = (bookingSlug) => ({
  type: SAVE_BOOKING_SLUG_SUCCESS,
  payload: bookingSlug
})
export const saveBookingSlugError = (error) => ({
  type: SAVE_BOOKING_SLUG_ERROR,
  payload: error
})

export const updateNotificationSettingsRequest = () => ({
  type: UPDATE_NOTIFICATION_SETTINGS_REQUEST
})
export const updateNotificationSettingsSuccess = (configs) => ({
  type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  payload: configs
})
export const updateNotificationSettingsError = (error) => ({
  type: UPDATE_NOTIFICATION_SETTINGS_ERROR,
  payload: error
})

export const addGoogleCalendarRequest = () => ({
  type: ADD_GOOGLE_CALENDAR_REQUEST
})
export const addGoogleCalendarSuccess = (payload) => ({
  type: ADD_GOOGLE_CALENDAR_SUCCESS,
  payload
})
export const addGoogleCalendarError = (error) => ({
  type: ADD_GOOGLE_CALENDAR_ERROR,
  payload: error
})
export const removeGoogleCalendarAuthUrl = () => ({
  type: REMOVE_GOOGLE_CALENDAR_AUTH_URL
})

export const startStripeDashboardSessionRequest = () => ({
  type: START_STRIPE_DASHBOARD_SESSION_REQUEST
})
export const startStripeDashboardSessionSuccess = (url) => ({
  type: START_STRIPE_DASHBOARD_SESSION_SUCCESS,
  payload: url
})
export const startStripeDashboardSessionError = (error) => ({
  type: START_STRIPE_DASHBOARD_SESSION_ERROR,
  payload: error
})
// ================ SELECTORS ================ //

export const selectBookingsPage = (state) => state.BookingsPage

export const selectOpenHours = (state) => selectBookingsPage(state).openHours
export const selectConfigurations = (state) => selectBookingsPage(state).configurations
export const selectAllowAdditionalGrants = (state) =>
  selectBookingsPage(state).allowAdditionalGrants

export const selectFetchBookingConfigInProgress = (state) =>
  selectBookingsPage(state).fetchBookingConfigInProgress
export const selectFetchBookingConfigError = (state) =>
  selectBookingsPage(state).fetchBookingConfigError

export const selectUpdateConfigurationInProgress = (state) =>
  selectBookingsPage(state).updateConfigurationInProgress
export const selectUpdateConfigurationError = (state) =>
  selectBookingsPage(state).updateConfigurationError

export const selectUpdateBookingCalendarsInProgress = (state) =>
  selectBookingsPage(state).updateBookingCalendarsInProgress
export const selectUpdateBookingCalendarsError = (state) =>
  selectBookingsPage(state).updateBookingCalendarsError

export const selectSaveBookingSlugInProgress = (state) =>
  selectBookingsPage(state).saveBookingSlugInProgress
export const selectSaveBookingSlugError = (state) => selectBookingsPage(state).saveBookingSlugError

export const selectUpdateNotificationSettingsInProgress = (state) =>
  selectBookingsPage(state).updateNotificationSettingsInProgress
export const selectUpdateNotificationSettingsError = (state) =>
  selectBookingsPage(state).updateNotificationSettingsError

export const selectAddGoogleCalendarAuthUrl = (state) =>
  selectBookingsPage(state).addGoogleCalendarAuthUrl
export const selectAddGoogleCalendarInProgress = (state) =>
  selectBookingsPage(state).addGoogleCalendarInProgress
export const selectAddGoogleCalendarError = (state) =>
  selectBookingsPage(state).addGoogleCalendarError

export const selectStartStripeDashboardSessionUrl = (state) =>
  selectBookingsPage(state).startStripeDashboardSessionUrl
export const selectStartStripeDashboardSessionInProgress = (state) =>
  selectBookingsPage(state).startStripeDashboardSessionInProgress
export const selectStartStripeDashboardSessionError = (state) =>
  selectBookingsPage(state).startStripeDashboardSessionError

// ================ THUNKS ================ //

const baseURL = 'http://127.0.0.1:5001/stella-develop/australia-southeast1/api/v1/pub/events'

export const fetchBookingConfigThunk = () => async (dispatch) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Australia/Melbourne'
  dispatch(fetchBookingConfigRequest())
  try {
    const result = await fetchPractitionerBookingConfig({ timezone })
    const data = result.response
    console.log('fetchBookingConfigThunk data', data)
    dispatch(fetchBookingConfigSuccess(data))
  } catch (error) {
    console.error('Error fetching events:', error)
    dispatch(fetchBookingConfigError(error.toString()))
  }
}

export const createEvent = (body) => async (dispatch) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Australia/Melbourne'
    await hcpCreateEvent({ ...body, timezone })
  } catch (error) {
    console.error('Error creating event:', error)
  }
}

export const updateEvent = (eventId, eventData) => async (dispatch) => {
  dispatch(updateEventRequest())
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Australia/Melbourne'
    const response = await fetch(`${baseURL}/${eventId}?timezone=${timezone}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(eventData)
    })
    if (!response.ok) {
      throw new Error('Failed to update event')
    }
    const data = await response.json()
    dispatch(updateEventSuccess(data))
  } catch (error) {
    console.error('Error updating event:', error)
    dispatch(updateEventError(error.toString()))
  }
}

export const updateConfiguration = (configurationId, configurationData) => async (dispatch) => {
  dispatch(updateConfigurationRequest())
  try {
    console.log('UPDATE CONFIGURATION DATA: ', {
      configurationId,
      configurationData
    })
    const result = await updateBookingConfigurations({ configurationId }, configurationData)
    console.log('result', result)
    dispatch(updateConfigurationSuccess(result.response))
  } catch (error) {
    console.error('Error updating configuration:', error)
    dispatch(updateConfigurationError(error.toString()))
  }
}

export const updateBookingCalendarsThunk = (calendarData) => async (dispatch) => {
  dispatch(updateBookingCalendarsRequest())
  try {
    console.log('UPDATE BOOKING CALENDARS DATA: ', calendarData)
    const result = await updateBookingCalendars(calendarData)
    console.log('result', result)
    dispatch(updateBookingCalendarsSuccess(result.response))
  } catch (error) {
    console.error('Error updating booking calendars:', error)
    dispatch(updateBookingCalendarsError(error.toString()))
  }
}

export const saveBookingSlugThunk = (slug) => async (dispatch) => {
  dispatch(saveBookingSlugRequest())
  try {
    const result = await saveBookingSlug({ slug })
    dispatch(saveBookingSlugSuccess(result.response))
  } catch (error) {
    console.error('Error saving booking slug:', error)
    dispatch(saveBookingSlugError(error.toString()))
  }
}

export const updateNotificationSettingsThunk =
  (bookingConfigId, notification) => async (dispatch) => {
    dispatch(updateNotificationSettingsRequest())
    try {
      const result = await updateNotificationConfiguration({ bookingConfigId, notification })
      dispatch(updateNotificationSettingsSuccess(result.response))
      return result.response
    } catch (error) {
      console.error('Error updating notification settings:', error)
      dispatch(updateNotificationSettingsError(error.toString()))
    }
  }

export const addGoogleCalendarThunk = () => async (dispatch) => {
  dispatch(addGoogleCalendarRequest())
  try {
    const result = await addGoogleCalendar()
    dispatch(addGoogleCalendarSuccess(result.response))
  } catch (error) {
    console.error('Error adding Google Calendar:', error)
    dispatch(addGoogleCalendarError(error.toString()))
  }
}

export const startStripeDashboardSessionThunk = () => async (dispatch) => {
  dispatch(startStripeDashboardSessionRequest())
  try {
    const result = await startStripeDashboardSession()
    dispatch(startStripeDashboardSessionSuccess(result.response))
    return result.response
  } catch (error) {
    console.error('Error starting Stripe Dashboard Session:', error)
    dispatch(startStripeDashboardSessionError(error.toString()))
  }
}

export const loadData = () => async (dispatch) => {
  await dispatch(fetchBookingConfigThunk())
}
