export const MedicalAestheticTreatments = {
  antiWrinkle: 'Anti-Wrinkle Injections',
  filler: 'Filler Injections',
  skinBooster: 'Skin Booster Injections',
  bioremodeling: 'Bioremodeling Injections',
  pdoThreads: 'PDO Threads',
  prp: 'PRP Injections',
  fatDissolving: 'Fat Dissolving Injections'
}

export const TreatmentCategories = {
  consult: 'Consult',
  medicalAesthetics: 'Medical Aesthetics Consult',
  facials: 'Facials',
  skin: 'Skin',
  body: 'Body',
  cosmeticTattooing: 'Cosmetic Tattooing',
  hairRestoration: 'Hair Restoration',
  hairRemoval: 'Hair Removal',
  wellness: 'Wellness'
}

export const ProductList = [
  {
    id: 'botox',
    name: 'Botox',
    treatment: [MedicalAestheticTreatments.antiWrinkle]
  },
  {
    id: 'dysport',
    name: 'Dysport',
    treatment: [MedicalAestheticTreatments.antiWrinkle]
  },
  {
    id: 'xeomin',
    name: 'Xeomin',
    treatment: [MedicalAestheticTreatments.antiWrinkle]
  },
  {
    id: 'letybo',
    name: 'Letybo',
    treatment: [MedicalAestheticTreatments.antiWrinkle]
  },

  {
    id: 'eptq-s100',
    name: 'E.P.T.Q. S100',
    treatment: [MedicalAestheticTreatments.antiWrinkle, MedicalAestheticTreatments.filler]
  },
  {
    id: 'eptq-s300',
    name: 'E.P.T.Q. S300',
    treatment: [MedicalAestheticTreatments.antiWrinkle, MedicalAestheticTreatments.filler]
  },
  {
    id: 'eptq-s500',
    name: 'E.P.T.Q. S500',
    treatment: [MedicalAestheticTreatments.antiWrinkle, MedicalAestheticTreatments.filler]
  },

  {
    id: 'juvederm-voluma-xc',
    name: 'Juvéderm Voluma XC',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'juvederm-vollure-xc',
    name: 'Juvéderm Vollure XC',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'juvederm-ultra-xc',
    name: 'Juvéderm Ultra XC',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'juvederm-ultra-plus-xc',
    name: 'Juvéderm Ultra Plus XC',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'juvederm-volbella-xc',
    name: 'Juvéderm Volbella XC',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'juvederm-volux-xc',
    name: 'Juvéderm Volux XC',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'juvederm-xc',
    name: 'Juvéderm XC',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'juvederm-volite',
    name: 'Juvéderm Volite',
    treatment: [MedicalAestheticTreatments.skinBooster]
  },
  {
    id: 'juvederm-volift',
    name: 'Juvéderm Volift',
    treatment: [MedicalAestheticTreatments.filler]
  },

  {
    id: 'restylane',
    name: 'Restylane',
    treatment: [MedicalAestheticTreatments.antiWrinkle, MedicalAestheticTreatments.filler]
  },
  {
    id: 'restylane-l',
    name: 'Restylane-L',
    treatment: [MedicalAestheticTreatments.antiWrinkle, MedicalAestheticTreatments.filler]
  },
  {
    id: 'restylane-lyft',
    name: 'Restylane Lyft',
    treatment: [MedicalAestheticTreatments.antiWrinkle, MedicalAestheticTreatments.filler]
  },
  {
    id: 'restylane-silk',
    name: 'Restylane Silk',
    treatment: [MedicalAestheticTreatments.antiWrinkle, MedicalAestheticTreatments.filler]
  },
  {
    id: 'restylane-kysse',
    name: 'Restylane Kysse',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'restylane-refyne',
    name: 'Restylane Refyne',
    treatment: [MedicalAestheticTreatments.antiWrinkle]
  },
  {
    id: 'restylane-defyne',
    name: 'Restylane Defyne',
    treatment: [MedicalAestheticTreatments.antiWrinkle]
  },
  {
    id: 'restylane-contour',
    name: 'Restylane Contour',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'restylane-eyelight',
    name: 'Restylane Eyelight',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'restylane-volyme',
    name: 'Restylane Volyme',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'restylane-skinbooster',
    name: 'Restylane Skinbooster',
    treatment: [MedicalAestheticTreatments.skinBooster]
  },

  {
    id: 'stylage-s',
    name: 'Stylage S',
    treatment: [MedicalAestheticTreatments.antiWrinkle]
  },
  {
    id: 'stylage-m',
    name: 'Stylage M',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'stylage-l',
    name: 'Stylage L',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'stylage-xxl',
    name: 'Stylage XXL',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'stylage-lips-plus',
    name: 'Stylage Lips Plus',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'stylage-special-lips',
    name: 'Stylage Special Lips',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'stylage-hydro',
    name: 'Stylage Hydro',
    treatment: [MedicalAestheticTreatments.skinBooster]
  },
  {
    id: 'stylage-hydromax',
    name: 'Stylage HydroMax',
    treatment: [MedicalAestheticTreatments.skinBooster]
  },

  {
    id: 'art-filler-universal',
    name: 'ART FILLER Universal',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'art-filler-volume',
    name: 'ART FILLER Volume',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'art-filler-lips',
    name: 'ART FILLER Lips',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'art-filler-lips-soft',
    name: 'ART FILLER Lips Soft',
    treatment: [MedicalAestheticTreatments.filler]
  },

  {
    id: 'croma-rich',
    name: 'Croma Rich',
    treatment: [MedicalAestheticTreatments.skinBooster]
  },
  {
    id: 'croma-filler',
    name: 'Croma Filler',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'croma-volume',
    name: 'Croma Volume',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'croma-volume-plus',
    name: 'Croma Volume Plus',
    treatment: [MedicalAestheticTreatments.filler]
  },

  {
    id: 'teosyal-rha1',
    name: 'Teosyal RHA 1',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'teosyal-rha2',
    name: 'Teosyal RHA 2',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'teosyal-rha3',
    name: 'Teosyal RHA 3',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'teosyal-rha4',
    name: 'Teosyal RHA 4',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'teosyal-puresense-redensity1',
    name: 'Teosyal PureSense Redensity 1',
    treatment: [MedicalAestheticTreatments.skinBooster]
  },
  {
    id: 'teosyal-puresense-redensity2',
    name: 'Teosyal PureSense Redensity 2',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'teosyal-ultra-deep',
    name: 'Teosyal Ultra Deep',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'teosyal-ultimate',
    name: 'Teosyal Ultimate',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'teosyal-puresense-global-action',
    name: 'Teosyal PureSense Global Action',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'teosyal-puresense-deep-lines',
    name: 'Teosyal PureSense Deep Lines',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'teosyal-puresense-kiss',
    name: 'Teosyal PureSense Kiss',
    treatment: [MedicalAestheticTreatments.filler]
  },

  {
    id: 'belotero-balance',
    name: 'Belotero Balance',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'belotero-intense',
    name: 'Belotero Intense',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'belotero-soft',
    name: 'Belotero Soft',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'belotero-volume',
    name: 'Belotero Volume',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'belotero-lips-contour',
    name: 'Belotero Lips Contour',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'belotero-lips-shape',
    name: 'Belotero Lips Shape',
    treatment: [MedicalAestheticTreatments.filler]
  },
  {
    id: 'belotero-revive',
    name: 'Belotero Revive',
    treatment: [MedicalAestheticTreatments.skinBooster]
  },

  {
    id: 'profhilo',
    name: 'Profhilo',
    treatment: [
      MedicalAestheticTreatments.filler,
      MedicalAestheticTreatments.skinBooster,
      MedicalAestheticTreatments.bioremodeling
    ]
  },

  {
    id: 'rejuran',
    name: 'Rejuran',
    treatment: [MedicalAestheticTreatments.bioremodeling]
  },
  {
    id: 'rejuran-i',
    name: 'Rejuran I',
    treatment: [MedicalAestheticTreatments.bioremodeling]
  },
  {
    id: 'rejuran-s',
    name: 'Rejuran S',
    treatment: [MedicalAestheticTreatments.bioremodeling]
  },

  {
    id: 'pdo-mono-threads',
    name: 'PDO Mono Threads',
    treatment: [MedicalAestheticTreatments.pdoThreads]
  },
  {
    id: 'pdo-cog-threads',
    name: 'PDO Cog Threads',
    treatment: [MedicalAestheticTreatments.pdoThreads]
  },
  {
    id: 'alocuro-pro-prp',
    name: 'Alocuro PRO-PRP',
    treatment: [MedicalAestheticTreatments.prp]
  },
  {
    id: 'medicell-prp-tubes',
    name: 'Medicell PRP Tubes',
    treatment: [MedicalAestheticTreatments.prp]
  },
  {
    id: 'stem-cell-infused-prp',
    name: 'Stem Cell Infused PRP',
    treatment: [MedicalAestheticTreatments.prp]
  },
  {
    id: 'belkyra',
    name: 'Belkyra',
    treatment: [MedicalAestheticTreatments.fatDissolving]
  },
  {
    id: 'pcdc',
    name: 'PCDC (Phosphatidylcholine/Deoxycholate)',
    treatment: [MedicalAestheticTreatments.fatDissolving]
  }
]

export const TreatmentConfig = {
  wrinkleManagement: {
    id: 'wrinkleManagement',
    name: 'Wrinkle Management',
    description: 'Anti-Wrinkle Injections',
    category: TreatmentCategories.medicalAesthetics,
    medical: true,
    medicalName: 'Anti Wrinkle Injections',
    priceUnit: 'unit'
  },
  volumeRestoration: {
    id: 'volumeRestoration',
    name: 'Volume Restoration',
    description: 'Filler Injections',
    category: TreatmentCategories.medicalAesthetics,
    medical: true,
    medicalName: 'Filler Injections',
    priceUnit: 'ml'
  },
  skinBoosting: {
    id: 'skinBoosting',
    name: 'Skin Boosting',
    description: 'Skin Booster, Bioremodeling, PDO Threads, and PRP Injections',
    category: TreatmentCategories.medicalAesthetics,
    medical: true,
    medicalName: 'Skin Boosting',
    priceUnit: 'ml'
  },
  fatDeposits: {
    id: 'fatDeposits',
    name: 'Fat Deposits Management',
    description: 'Fat Dissolving Injections',
    category: TreatmentCategories.medicalAesthetics,
    medical: true,
    medicalName: 'Fat Dissolving Injections',
    priceUnit: 'ml'
  },
  facials: {
    id: 'facials',
    name: 'Facials',
    category: TreatmentCategories.facials,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  acneTreatments: {
    id: 'acneTreatments',
    name: 'Acne Treatments',
    category: TreatmentCategories.facials,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  hydrafacial: {
    id: 'hydrafacial',
    name: 'Hydrafacial',
    category: TreatmentCategories.facials,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  chemicalPeel: {
    id: 'chemicalPeel',
    name: 'Chemical Peels',
    category: TreatmentCategories.facials,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  microdermabrasion: {
    id: 'microdermabrasion',
    name: 'Microdermabrasion',
    category: TreatmentCategories.facials,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  ledTherapy: {
    id: 'ledLightTherapy',
    name: 'LED Light Therapy',
    category: TreatmentCategories.facials,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  microneedling: {
    id: 'microneedling',
    name: 'Microneedling',
    category: TreatmentCategories.facials,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  plasmaPen: {
    id: 'plasmaPen',
    name: 'Plasma Pen',
    category: TreatmentCategories.facials,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  oxygenFacials: {
    id: 'oxygenFacials',
    name: 'Oxygen Facials',
    category: TreatmentCategories.facials,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  exosomesSkin: {
    id: 'exosomesSkin',
    name: 'Exosomes for Skin',
    category: TreatmentCategories.facials,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  rejuran: {
    id: 'rejuran',
    name: 'Rejuran (Poly-nucleotides)',
    category: TreatmentCategories.skin,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  laserSkinResurfacing: {
    id: 'laserSkinResurfacing',
    name: 'Laser Skin Resurfacing',
    category: TreatmentCategories.skin,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  ipl: {
    id: 'ipl',
    name: 'IPL (Intense Pulsed Light)',
    category: TreatmentCategories.skin,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  photofacials: {
    id: 'photofacials',
    name: 'Photofacials',
    category: TreatmentCategories.skin,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  nctfSkinBooster: {
    id: 'nctfSkinBooster',
    name: 'NCTF Skin Booster',
    category: TreatmentCategories.skin,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  pigmentRemoval: {
    id: 'pigmentRemoval',
    name: 'Pigment Removal',
    category: TreatmentCategories.skin,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  fatFreezing: {
    id: 'fatFreezing',
    name: 'Fat Freezing (Cryolipolysis)',
    category: TreatmentCategories.body,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  ultrasonicFatCavitation: {
    id: 'ultrasonicFatCavitation',
    name: 'UltraSonic Fat Cavitation',
    category: TreatmentCategories.body,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  muscleDefining: {
    id: 'muscleDefining',
    name: 'Muscle Defining',
    category: TreatmentCategories.body,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  celluliteReduction: {
    id: 'celluliteReduction',
    name: 'Cellulite Reduction',
    category: TreatmentCategories.body,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  skinTightening: {
    id: 'skinTightening',
    name: 'Skin Tightening (RF / HIFU)',
    category: TreatmentCategories.body,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  laserHairRemoval: {
    id: 'laserHairRemoval',
    name: 'Laser Hair Removal',
    category: TreatmentCategories.hairRemoval,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  prpHairLoss: {
    id: 'prpHairLoss',
    name: 'PRP (Platelet-Rich Plasma) for Hair Loss',
    category: TreatmentCategories.hairRestoration,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  scalpMicroneedling: {
    id: 'scalpMicroneedling',
    name: 'Scalp Microneedling',
    category: TreatmentCategories.hairRestoration,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  exosomesHair: {
    id: 'exosomesHair',
    name: 'Exosomes for Hair',
    category: TreatmentCategories.hairRestoration,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  pelvicFloorStrengthening: {
    id: 'pelvicFloorStrengthening',
    name: 'Pelvic Floor Strengthening',
    category: TreatmentCategories.wellness,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  },
  ivVitaminInfusions: {
    id: 'ivVitaminInfusions',
    name: 'IV Vitamin Infusions',
    category: TreatmentCategories.wellness,
    medical: false,
    medicalName: '',
    priceUnit: '',
    price: []
  }
}

export const PublicGeneralTreatmentConfig = [
  {
    id: 'consultation',
    category: TreatmentCategories.consult,
    name: 'Consultation'
  },
  {
    id: 'followUp',
    category: TreatmentCategories.consult,
    name: 'Follow Up'
  }
]

export const PublicTreatmentConfig = [
  {
    id: 'acneTreatments',
    category: TreatmentCategories.facials,
    name: 'Acne Treatments'
  },
  {
    id: 'celluliteReduction',
    category: TreatmentCategories.body,
    name: 'Cellulite Reduction'
  },
  {
    id: 'chemicalPeel',
    category: TreatmentCategories.facials,
    name: 'Chemical Peels'
  },
  {
    id: 'exosomesHair',
    category: TreatmentCategories.hairRestoration,
    name: 'Exosomes for Hair'
  },
  {
    id: 'exosomesSkin',
    category: TreatmentCategories.facials,
    name: 'Exosomes for Skin'
  },
  {
    id: 'facials',
    category: TreatmentCategories.facials,
    name: 'Facials'
  },
  {
    id: 'fatDeposits',
    category: TreatmentCategories.medicalAesthetics,
    name: 'Fat Deposits Management'
  },
  {
    id: 'fatFreezing',
    category: TreatmentCategories.body,
    name: 'Fat Freezing (Cryolipolysis)'
  },
  {
    id: 'hydrafacial',
    category: TreatmentCategories.facials,
    name: 'Hydrafacial'
  },
  {
    id: 'ipl',
    category: TreatmentCategories.skin,
    name: 'IPL (Intense Pulsed Light)'
  },
  {
    id: 'ivVitaminInfusions',
    category: TreatmentCategories.wellness,
    name: 'IV Vitamin Infusions'
  },
  {
    id: 'laserHairRemoval',
    category: TreatmentCategories.hairRemoval,
    name: 'Laser Hair Removal'
  },
  {
    id: 'laserSkinResurfacing',
    category: TreatmentCategories.skin,
    name: 'Laser Skin Resurfacing'
  },
  {
    id: 'ledLightTherapy',
    category: TreatmentCategories.facials,
    name: 'LED Light Therapy'
  },
  {
    id: 'microdermabrasion',
    category: TreatmentCategories.facials,
    name: 'Microdermabrasion'
  },
  {
    id: 'microneedling',
    category: TreatmentCategories.facials,
    name: 'Microneedling'
  },
  {
    id: 'muscleDefining',
    category: TreatmentCategories.body,
    name: 'Muscle Defining'
  },
  {
    id: 'nctfSkinBooster',
    category: TreatmentCategories.skin,
    name: 'NCTF Skin Booster'
  },
  {
    id: 'oxygenFacials',
    category: TreatmentCategories.facials,
    name: 'Oxygen Facials'
  },
  {
    id: 'pelvicFloorStrengthening',
    category: TreatmentCategories.wellness,
    name: 'Pelvic Floor Strengthening'
  },
  {
    id: 'photofacials',
    category: TreatmentCategories.skin,
    name: 'Photofacials'
  },
  {
    id: 'pigmentRemoval',
    category: TreatmentCategories.skin,
    name: 'Pigment Removal'
  },
  {
    id: 'plasmaPen',
    category: TreatmentCategories.facials,
    name: 'Plasma Pen'
  },
  {
    id: 'prpHairLoss',
    category: TreatmentCategories.hairRestoration,
    name: 'PRP (Platelet-Rich Plasma) for Hair Loss'
  },
  {
    id: 'rejuran',
    category: TreatmentCategories.skin,
    name: 'Rejuran (Poly-nucleotides)'
  },
  {
    id: 'scalpMicroneedling',
    category: TreatmentCategories.hairRestoration,
    name: 'Scalp Microneedling'
  },
  {
    id: 'skinBoosting',
    category: TreatmentCategories.medicalAesthetics,
    name: 'Skin Boosting'
  },
  {
    id: 'skinTightening',
    category: TreatmentCategories.body,
    name: 'Skin Tightening (RF / HIFU)'
  },
  {
    id: 'ultrasonicFatCavitation',
    category: TreatmentCategories.body,
    name: 'Ultrasonic Fat Cavitation'
  },
  {
    id: 'volumeRestoration',
    category: TreatmentCategories.medicalAesthetics,
    name: 'Volume Restoration'
  },
  {
    id: 'wrinkleManagement',
    category: TreatmentCategories.medicalAesthetics,
    name: 'Wrinkle Management'
  }
]
