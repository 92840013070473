import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  const backgroundColors = {
    primary: palette.primary.main,
    secondary: palette.common.transparent,
    tertiary: palette.common.transparent,
    quaternary: palette.common.transparent,
    danger: palette.common.transparent
  }

  const backgroundLoadingColors = {
    primary: palette.primary.light,
    secondary: palette.common.transparent,
    tertiary: palette.common.transparent,
    quaternary: palette.common.transparent,
    danger: palette.common.transparent
  }

  const backgroundHoverColors = {
    primary: palette.primary.dark,
    secondary: palette.primary.light,
    tertiary: palette.grey['200'],
    quaternary: palette.primary.light,
    danger: palette.error.main
  }

  const borderColors = {
    primary: palette.common.transparent,
    secondary: palette.primary.main,
    tertiary: palette.common.transparent,
    quaternary: palette.common.transparent,
    danger: palette.error.main.light
  }

  const textColors = {
    primary: palette.button.text.primary,
    secondary: palette.button.text.secondary,
    tertiary: palette.button.text.tertiary,
    quaternary: palette.button.text.secondary,
    danger: palette.error.main
  }

  const textHoverColors = {
    danger: palette.button.text.primary
  }

  return {
    container: {
      fontSize: '15px',
      color: ({ uiType, isLoading, isDisabled }) =>
        isLoading
          ? palette.button.text.secondary
          : isDisabled
            ? palette.text.disabled
            : textColors[uiType],
      textTransform: 'none',
      fontFamily: 'Inter',
      fontWeight: 500,
      boxShadow: 'none',
      letterSpacing: 0,
      backgroundColor: ({ uiType, isLoading }) =>
        isLoading ? backgroundLoadingColors[uiType] : backgroundColors[uiType],
      borderColor: ({ uiType }) => borderColors[uiType],
      '&:hover': {
        backgroundColor: ({ uiType }) => backgroundHoverColors[uiType],
        color: ({ uiType }) => textHoverColors[uiType],
        boxShadow: 'none'
      },
      '&.Mui-disabled': {
        borderColor: palette.grey['200']
      }
    }
  }
})

export const useButtonStyles = ({ uiType, isLoading, isDisabled, className }) => {
  const variants = {
    primary: 'contained',
    secondary: 'outlined',
    tertiary: 'text',
    quaternary: 'text'
  }

  const variant = variants[uiType]

  const classes = useStyles({ uiType, isLoading, isDisabled })
  const joinedClasses = classNames(classes.container, className)

  return { variant, joinedClasses }
}
