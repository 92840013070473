import { useEffect } from 'react'

const ExternalRedirect = (props) => {
  const { to, from } = props

  useEffect(() => {
    const search = new URLSearchParams()

    if (from) {
      search.append('from', from)
    }

    if (window) {
      window.location.replace(from ? `${to}?${search.toString()}` : to)
    } else {
      console.error('ExternalRedirect: window is not defined', { to, from })
    }
  }, [])

  return <></>
}

export default ExternalRedirect
