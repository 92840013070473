import { cancelClientBooking, fetchClientBookingDetails } from '../../util/api'

// ================ ACTION TYPES ================ //

export const FETCH_CLIENT_BOOKING_DETAILS_REQUEST =
  'app/ClientBookingDetailsPage/FETCH_CLIENT_BOOKING_DETAILS_REQUEST'
export const FETCH_CLIENT_BOOKING_DETAILS_SUCCESS =
  'app/ClientBookingDetailsPage/FETCH_CLIENT_BOOKING_DETAILS_SUCCESS'
export const FETCH_CLIENT_BOOKING_DETAILS_ERROR =
  'app/ClientBookingDetailsPage/FETCH_CLIENT_BOOKING_DETAILS_ERROR'
export const SET_BOOKING_ID = 'app/ClientBookingDetailsPage/SET_BOOKING_ID'

export const CANCEL_CLIENT_BOOKING_REQUEST =
  'app/ClientBookingDetailsPage/CANCEL_CLIENT_BOOKING_REQUEST'
export const CANCEL_CLIENT_BOOKING_SUCCESS =
  'app/ClientBookingDetailsPage/CANCEL_CLIENT_BOOKING_SUCCESS'
export const CANCEL_CLIENT_BOOKING_ERROR =
  'app/ClientBookingDetailsPage/CANCEL_CLIENT_BOOKING_ERROR'

// ================ REDUCER ================ //

const initialState = {
  bookingId: '',
  clientBookingDetails: null,
  fetchClientBookingDetailsInProgress: false,
  fetchClientBookingDetailsError: null,
  cancelClientBookingInProgress: false,
  cancelClientBookingSuccess: false,
  cancelClientBookingError: null,
  cancelClientBookingMessage: null
}

const bookingDetailsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_CLIENT_BOOKING_DETAILS_REQUEST:
      return {
        ...state,
        fetchClientBookingDetailsInProgress: true,
        fetchClientBookingDetailsError: null
      }
    case FETCH_CLIENT_BOOKING_DETAILS_SUCCESS:
      return { ...state, fetchClientBookingDetailsInProgress: false, clientBookingDetails: payload }
    case FETCH_CLIENT_BOOKING_DETAILS_ERROR:
      return {
        ...state,
        fetchClientBookingDetailsInProgress: false,
        fetchClientBookingDetailsError: payload
      }
    case SET_BOOKING_ID:
      return { ...state, bookingId: payload }
    case CANCEL_CLIENT_BOOKING_REQUEST:
      return {
        ...state,
        cancelClientBookingInProgress: true,
        cancelClientBookingError: null,
        cancelClientBookingMessage: null
      }
    case CANCEL_CLIENT_BOOKING_SUCCESS:
      return {
        ...state,
        clientBookingDetails: {
          ...state.clientBookingDetails,
          booking: payload.booking
        },
        cancelClientBookingInProgress: false,
        cancelClientBookingSuccess: true,
        cancelClientBookingMessage: payload.message || 'Booking cancelled successfully'
      }
    case CANCEL_CLIENT_BOOKING_ERROR:
      return {
        ...state,
        cancelClientBookingInProgress: false,
        cancelClientBookingError: payload.error,
        cancelClientBookingMessage: payload.message
      }
    default:
      return state
  }
}

export default bookingDetailsReducer

// ================ ACTION CREATORS ================ //

export const fetchClientBookingDetailsRequest = () => ({
  type: FETCH_CLIENT_BOOKING_DETAILS_REQUEST
})

export const fetchClientBookingDetailsSuccess = (details) => ({
  type: FETCH_CLIENT_BOOKING_DETAILS_SUCCESS,
  payload: details
})

export const fetchClientBookingDetailsError = (error) => ({
  type: FETCH_CLIENT_BOOKING_DETAILS_ERROR,
  payload: error
})

export const setBookingId = (id) => ({
  type: SET_BOOKING_ID,
  payload: id
})

export const cancelClientBookingRequest = () => ({
  type: CANCEL_CLIENT_BOOKING_REQUEST
})

export const cancelClientBookingSuccess = (booking, message) => ({
  type: CANCEL_CLIENT_BOOKING_SUCCESS,
  payload: { booking, message }
})

export const cancelClientBookingError = (error, message) => ({
  type: CANCEL_CLIENT_BOOKING_ERROR,
  payload: { error, message }
})

// ================ SELECTORS ================ //

export const selectClientBookingDetailsPage = (state) => state.ClientBookingDetailsPage

export const selectClientBookingDetails = (state) =>
  selectClientBookingDetailsPage(state).clientBookingDetails
export const selectFetchClientBookingDetailsInProgress = (state) =>
  selectClientBookingDetailsPage(state).fetchClientBookingDetailsInProgress
export const selectFetchClientBookingDetailsError = (state) =>
  selectClientBookingDetailsPage(state).fetchClientBookingDetailsError
export const selectBookingId = (state) => selectClientBookingDetailsPage(state).bookingId
export const selectCancelClientBookingInProgress = (state) =>
  selectClientBookingDetailsPage(state).cancelClientBookingInProgress
export const selectCancelClientBookingSuccess = (state) =>
  selectClientBookingDetailsPage(state).cancelClientBookingSuccess
export const selectCancelClientBookingError = (state) =>
  selectClientBookingDetailsPage(state).cancelClientBookingError
export const selectCancelClientBookingMessage = (state) =>
  selectClientBookingDetailsPage(state).cancelClientBookingMessage

// ================ THUNKS ================ //

export const fetchClientBookingDetailsThunk = (id) => async (dispatch) => {
  dispatch(fetchClientBookingDetailsRequest())
  try {
    const result = await fetchClientBookingDetails(id)
    if (result.response) {
      dispatch(fetchClientBookingDetailsSuccess(result.response))
    } else {
      dispatch(fetchClientBookingDetailsError(result.error))
    }
  } catch (error) {
    console.error('Error fetching client booking details:', error)
    dispatch(fetchClientBookingDetailsError(error.toString()))
  }
}

export const cancelClientBookingThunk = (bookingId, cancellationReason) => async (dispatch) => {
  dispatch(cancelClientBookingRequest())
  try {
    const result = await cancelClientBooking({ bookingId }, { cancellationReason })
    if (result.response && result.response.success) {
      dispatch(cancelClientBookingSuccess(result.response.booking, result.response.message))
    } else {
      dispatch(cancelClientBookingError(true, result?.response?.message || 'Cancellation failed'))
    }
  } catch (error) {
    console.error('Error cancelling client booking:', error)
    dispatch(cancelClientBookingError(true, 'Cancellation failed'))
  }
}

// ================ LOAD DATA ================ //

export const loadData = (params) => async (dispatch) => {
  dispatch(setBookingId(params.id))
  await dispatch(fetchClientBookingDetailsThunk({ bookingId: params.id }))
}
