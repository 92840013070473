import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'

import { BottomMobileNavigation, MenuItem, UserMenuDropdown } from './components'
import { useTopbarStyles } from './hooks'

import { Button } from '../Button'

import { useLocation } from 'react-router-dom'
import LOGO from '../../../assets/stella-logo.svg'
import { loginUrl, signUpUrl } from '../../../util/api'
import { getIsUserProvider } from '../../../util/new/currentUser'

export const Topbar = ({ authInProgress, currentUser, isAuthenticated, onLogout }) => {
  const [activeMenuItem, setActiveMenuItem] = useState('')
  const { classes } = useTopbarStyles()

  const [isSignInLoading, setIsSignInLoading] = useState(false)
  const [isSignUpLoading, setIsSignUpLoading] = useState(false)

  const location = useLocation()

  const isProvider = getIsUserProvider(currentUser)

  useEffect(() => {
    const path = window.location.pathname

    if (path.endsWith('/s')) {
      setActiveMenuItem('/s')
    } else if (path.includes('/online-concierge')) {
      setActiveMenuItem('/online-concierge')
    } else if (path.includes('/post-goal')) {
      setActiveMenuItem('/post-goal')
    } else if (path.includes('/listings')) {
      setActiveMenuItem('/listings')
    } else if (path.includes('/requests')) {
      setActiveMenuItem('/requests')
    } else if (path.includes('/bookings')) {
      setActiveMenuItem('/bookings')
    }
  }, [])

  const renderMenuItems = () => {
    if (isAuthenticated && isProvider) {
      return (
        <Box className={classes.menuItemContainer}>
          <MenuItem
            name="SearchPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/s'}
          >
            Find a practitioner
          </MenuItem>
          <MenuItem
            name="ManageListingsPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/listings'}
          >
            Dashboard
          </MenuItem>
          <MenuItem
            name="BookingsPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/bookings'}
          >
            Bookings
          </MenuItem>
          <MenuItem
            name="ConsultRequestsPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/requests'}
          >
            Requests
          </MenuItem>
        </Box>
      )
    }

    return (
      <Box className={classes.menuItemContainer}>
        <MenuItem name="SearchPage" className={classes.menuItem} isActive={activeMenuItem === '/s'}>
          Find a practitioner
        </MenuItem>
        <MenuItem
          name="PostConcernPage"
          className={classes.menuItem}
          isActive={activeMenuItem === '/post-goal'}
        >
          Post a goal
        </MenuItem>
        <MenuItem
          name="OnlineConciergePage"
          className={classes.menuItem}
          isActive={activeMenuItem === '/online-concierge'}
        >
          Online concierge
        </MenuItem>
      </Box>
    )
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          <a href="https://www.seestella.com.au/">
            <img src={LOGO} alt="stella-logo" className={classes.logo} />
          </a>
          {renderMenuItems()}
          {!authInProgress && isAuthenticated ? (
            <UserMenuDropdown currentUser={currentUser} onLogout={onLogout} />
          ) : (
            <Box className={classes.authButtonsContainer}>
              <Button
                uiType="tertiary"
                onClick={() => setIsSignInLoading(true)}
                href={loginUrl(location.pathname + location.search)}
                isLoading={isSignInLoading}
                size="large"
                className={classes.signInButton}
              >
                Sign in
              </Button>
              <Button
                uiType="primary"
                onClick={() => setIsSignUpLoading(true)}
                href={signUpUrl(location.pathname + location.search)}
                isLoading={isSignUpLoading}
                size="large"
                className={classes.signUpButton}
              >
                Sign up
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <BottomMobileNavigation isAuthenticated={isAuthenticated} currentUser={currentUser} />
    </>
  )
}
