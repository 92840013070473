// BookingSchedulerPage.duck.js

import { bookingRequest, fetchAvailability } from '../../util/api'
import { parse } from '../../util/urlHelpers'

// ================ ACTION TYPES ================ //

export const FETCH_AVAILABILITY_REQUEST = 'app/BookingSchedulerPage/FETCH_AVAILABILITY_REQUEST'
export const FETCH_AVAILABILITY_SUCCESS = 'app/BookingSchedulerPage/FETCH_AVAILABILITY_SUCCESS'
export const FETCH_AVAILABILITY_ERROR = 'app/BookingSchedulerPage/FETCH_AVAILABILITY_ERROR'
export const SET_SLUG = 'app/BookingSchedulerPage/SET_SLUG'

export const BOOKING_REQUEST = 'app/BookingSchedulerPage/BOOKING_REQUEST'
export const BOOKING_SUCCESS = 'app/BookingSchedulerPage/BOOKING_SUCCESS'
export const BOOKING_ERROR = 'app/BookingSchedulerPage/BOOKING_ERROR'

export const RESET_PAGE_STATE = 'app/ClientBookingDetailsPage/RESET_PAGE_STATE'

// ================ REDUCER ================ //

const initialState = {
  slug: '',
  availability: null,
  fetchAvailabilityInProgress: false,
  fetchAvailabilityError: null,
  bookingData: null,
  bookingInProgress: false,
  bookingError: null
}

const bookingSchedulerReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_AVAILABILITY_REQUEST:
      return { ...state, fetchAvailabilityInProgress: true, fetchAvailabilityError: null }
    case FETCH_AVAILABILITY_SUCCESS:
      return { ...state, fetchAvailabilityInProgress: false, availability: payload }
    case FETCH_AVAILABILITY_ERROR:
      return { ...state, fetchAvailabilityInProgress: false, fetchAvailabilityError: payload }
    case BOOKING_REQUEST:
      return { ...state, bookingInProgress: true, bookingError: null }
    case BOOKING_SUCCESS:
      return { ...state, bookingInProgress: false, bookingData: payload }
    case BOOKING_ERROR:
      return { ...state, bookingInProgress: false, bookingError: payload }
    case SET_SLUG:
      return { ...state, slug: payload }
    case RESET_PAGE_STATE:
      return initialState
    default:
      return state
  }
}

export default bookingSchedulerReducer

// ================ ACTION CREATORS ================ //

export const fetchAvailabilityRequest = () => ({
  type: FETCH_AVAILABILITY_REQUEST
})

export const fetchAvailabilitySuccess = (availability) => ({
  type: FETCH_AVAILABILITY_SUCCESS,
  payload: availability
})

export const fetchAvailabilityError = (error) => ({
  type: FETCH_AVAILABILITY_ERROR,
  payload: error
})

export const bookingRequestAction = () => ({
  type: BOOKING_REQUEST
})

export const bookingSuccess = (data) => ({
  type: BOOKING_SUCCESS,
  payload: data
})

export const bookingError = (error) => ({
  type: BOOKING_ERROR,
  payload: error
})

export const setSlug = (slug) => ({
  type: SET_SLUG,
  payload: slug
})

export const resetPageState = () => ({
  type: RESET_PAGE_STATE
})
// ================ SELECTORS ================ //

export const selectBookingSchedulerPage = (state) => state.BookingSchedulerPage

export const selectAvailability = (state) => selectBookingSchedulerPage(state).availability
export const selectFetchAvailabilityInProgress = (state) =>
  selectBookingSchedulerPage(state).fetchAvailabilityInProgress
export const selectFetchAvailabilityError = (state) =>
  selectBookingSchedulerPage(state).fetchAvailabilityError
export const selectBookingData = (state) => selectBookingSchedulerPage(state).bookingData
export const selectBookingInProgress = (state) =>
  selectBookingSchedulerPage(state).bookingInProgress
export const selectBookingError = (state) => selectBookingSchedulerPage(state).bookingError
export const selectSlug = (state) => selectBookingSchedulerPage(state).slug

// ================ THUNKS ================ //

export const fetchAvailabilityThunk =
  ({ configId, rescheduleId }) =>
  async (dispatch) => {
    dispatch(fetchAvailabilityRequest())
    try {
      const result = await fetchAvailability({ configId, rescheduleId })
      if (result.response) {
        dispatch(fetchAvailabilitySuccess(result.response))
      } else {
        dispatch(fetchAvailabilityError('We could not fetch the availability. Please try again.'))
      }
    } catch (error) {
      console.error('Error fetching availability:', error)
      dispatch(fetchAvailabilityError('We could not fetch the availability. Please try again.'))
    }
  }

export const bookingRequestThunk = (body) => async (dispatch) => {
  dispatch(bookingRequestAction())
  try {
    const result = await bookingRequest(body)
    if (result.response) {
      dispatch(bookingSuccess(result.response))
    } else {
      dispatch(bookingError('We could not process your booking. Please try again.'))
    }
  } catch (error) {
    console.error('Error during booking request:', error)
    dispatch(bookingError('We could not process your booking. Please try again.'))
  }
}

// ================ LOAD DATA ================ //

export const loadData = (params, search) => async (dispatch) => {
  // Dispatch the slug to the state
  dispatch(setSlug(params.slug))

  const urlParams = parse(search)
  if (urlParams.reschedule) {
    // Fetch availability data
    await dispatch(
      fetchAvailabilityThunk({ configId: params.id, rescheduleId: urlParams.reschedule })
    )
  } else {
    // Fetch availability data
    await dispatch(fetchAvailabilityThunk({ configId: params.id }))
  }
}
